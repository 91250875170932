/* eslint-disable no-use-before-define */
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  IconButton,
  List,
  ListSubheader,
  SvgIcon,
  Typography,
  makeStyles,
} from '@material-ui/core';
import NextLink from 'next/link';
import { useRouter } from 'next/router';
import { useEffect } from 'react';
import { LogOut as LogOutIcon } from 'react-feather';

import { useAuthContext } from '@/contexts/auth';

import NavItem from './NavItem';

const Logo = () => <Typography variant="h3">CPMIS</Typography>;

type Item = {
  title: string;
  icon?: React.ElementType;
  href: string;
  items?: Item[];
  info?: React.ElementType;
};
type Section = {
  subheader?: string;
  items: Item[];
};

const sections: Section[] = [
  {
    // subheader: 'Management',
    items: [
      {
        title: 'Dashboard',
        href: '/dashboard',
      },
      {
        title: 'Transactions',
        href: '/transactions',
        items: [
          {
            title: 'Transaction List',
            href: '/transactions',
          },
          {
            title: 'Add transaction',
            href: '/transactions/add',
          },
        ],
      },
      {
        title: 'Clients',
        href: '/clients',
        items: [
          {
            title: 'Client List',
            href: '/clients',
          },
          {
            title: 'Add client',
            href: '/clients/add',
          },
          { title: 'Merge clients', href: '/clients/merge' },
        ],
      },
      {
        title: 'Vehicles',
        href: '/vehicles',
        items: [
          {
            title: 'Vehicle List',
            href: '/vehicles',
          },
        ],
      },

      {
        title: 'Agents',
        href: '/agents',
        items: [
          {
            title: 'Agent List',
            href: '/agents',
          },
          {
            title: 'Add Agent',
            href: '/agents/add',
          },
        ],
      },
      {
        title: 'Ins. Companies',
        href: '/insurance_companies',
        items: [
          {
            title: 'Company list List',
            href: '/insurance_companies',
          },
          {
            title: 'Add Insurance Company',
            href: '/insurance_companies/add',
          },
        ],
      },
      {
        title: 'Introducers',
        href: '/introducers',
        items: [
          {
            title: 'Introducer List',
            href: '/introducers',
          },
          {
            title: 'Add Introducer',
            href: '/introducers/add',
          },
        ],
      },
      {
        title: 'DELETED',
        items: [
          { title: 'Transactions', href: '/deleted/transactions' },
          { title: 'Vehicles', href: '/deleted/vehicles' },
          { title: 'Clients', href: '/deleted/clients' },
        ],
        href: '',
      },
      {
        title: 'Stats',
        href: '/stats',
      },
    ],
  },
];

function renderNavItems({
  items,
  pathname,
  depth = 0,
}: {
  items: Item[];
  pathname: string;
  depth?: number;
}) {
  return (
    <List disablePadding>
      {items.reduce(
        (acc, item) => reduceChildRoutes({ acc, item, pathname, depth }),
        []
      )}
    </List>
  );
}

function reduceChildRoutes({
  acc,
  pathname,
  item,
  depth,
}: {
  acc: JSX.Element[];
  item: Item;
  pathname: string;
  depth: number;
}) {
  const key = item.title + depth;

  if (item.items) {
    const open = pathname.startsWith(item.href);

    acc.push(
      <NavItem
        depth={depth}
        icon={item.icon}
        info={item.info}
        key={key}
        open={open}
        title={item.title}
      >
        {renderNavItems({
          depth: depth + 1,
          pathname,
          items: item.items,
        })}
      </NavItem>
    );
  } else {
    acc.push(
      <NavItem
        depth={depth}
        href={item.href}
        icon={item.icon}
        info={item.info}
        key={key}
        title={item.title}
      />
    );
  }

  return acc;
}

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
  },
}));

type NavBarProps = {
  onMobileClose: () => void;
  openMobile: boolean;
};

const NavBar = ({ onMobileClose, openMobile }: NavBarProps) => {
  const classes = useStyles();
  const { pathname } = useRouter();
  const { logout } = useAuthContext();
  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Hidden lgUp>
        <Box p={3} display="flex" justifyContent="center" alignItems="center">
          <NextLink href="/">
            <>
              <Logo />
            </>
          </NextLink>
          <Box ml="auto">
            <IconButton onClick={logout}>
              <SvgIcon fontSize="small">
                <LogOutIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Box>
      </Hidden>

      <Divider />
      <Box p={2}>
        {sections.map((section, i) => (
          <List
            key={i}
            subheader={
              <ListSubheader disableGutters disableSticky>
                {section.subheader}
              </ListSubheader>
            }
          >
            {renderNavItems({
              items: section.items,
              pathname,
            })}
          </List>
        ))}
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

export default NavBar;
