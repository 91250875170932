import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  SvgIcon,
  Toolbar,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import NextLink from 'next/link';
import { LogOut as LogOutIcon, Menu as MenuIcon } from 'react-feather';

import { useAuthContext } from '@/contexts/auth';

import Account from './Account';
// import Logo from '@/components/Logo';
const Logo = () => (
  <div
    style={{
      textDecoration: 'none',
      color: 'white',
      fontSize: 18,
      fontWeight: 600,
    }}
  >
    CPMIS
  </div>
);
// import { THEMES } from '@/constants';
// import Account from './Account';
// import Contacts from './Contacts';
// import Notifications from './Notifications';
// import Search from './Search';
// import Settings from './Settings';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
  },
  toolbar: {
    minHeight: 64,
  },
}));

type TopBarProps = {
  className?: string;
  onMobileNavOpen?: () => void;
};

const TopBar = ({ className, onMobileNavOpen = () => {} }: TopBarProps) => {
  const classes = useStyles();
  const { logout, user } = useAuthContext();
  return (
    <AppBar className={clsx(classes.root, className)}>
      <Toolbar className={classes.toolbar}>
        {user && (
          <>
            <Hidden lgUp>
              <IconButton color="inherit" onClick={onMobileNavOpen}>
                <SvgIcon fontSize="small">
                  <MenuIcon />
                </SvgIcon>
              </IconButton>
            </Hidden>

            <NextLink href="/">
              <a>
                <Logo />
              </a>
            </NextLink>
            <Box ml={2} flexGrow={1} />
            <Box ml={2}>
              <Account />
            </Box>
            <Box ml={2}>
              <IconButton onClick={logout}>
                <SvgIcon>
                  <LogOutIcon color="white" />
                </SvgIcon>
              </IconButton>
            </Box>
          </>
        )}
        {!user && <Logo />}
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
