import { Box, CircularProgress, makeStyles } from '@material-ui/core';
import { useRouter } from 'next/router';
import React, { useEffect, useRef, useState } from 'react';

import { useAuthContext } from '@/contexts/auth';

import NavBar from './NavBar';
import TopBar from './TopBar';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: ({ isLoginPage = false }: { isLoginPage?: boolean }) =>
        isLoginPage ? 0 : 256,
    },
  },
  loginWrapper: {
    paddingLeft: 0,
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    backgroundColor: theme.palette.background.dark,
  },
}));

const Layout: React.FC = ({ children }) => {
  const { loading, user } = useAuthContext();

  const layoutRef = useRef<HTMLDivElement>();
  const router = useRouter();
  router.events.on('routeChangeComplete', () => {
    layoutRef.current?.scrollTo(0, 0);
  });
  useEffect(() => {
    if (!loading && !user && router.pathname !== '/login') {
      router.push('/login');
    }
    if (!loading && user && router.pathname === '/login') {
      router.push('/');
    }
  }, [loading, user, router]);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);
  const isLoginPage = router.pathname === '/login';
  const classes = useStyles({ isLoginPage });
  return (
    <div className={classes.root}>
      <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} />
      {loading ||
      (router.pathname !== '/login' && !user) ||
      (router.pathname === '/login' && user) ? (
        <LoadingScreen />
      ) : (
        <>
          {!isLoginPage && (
            <NavBar
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={isMobileNavOpen}
            />
          )}
          <div className={classes.wrapper}>
            <div className={classes.content} ref={layoutRef}>
              {children}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default Layout;

const LoadingScreen = () => {
  return (
    <Box
      height="100vh"
      width="100vw"
      display="flex"
      justifyContent="center"
      alignItems="center"
    >
      <CircularProgress />
    </Box>
  );
};
