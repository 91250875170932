import axios from 'axios';
import { useSnackbar } from 'notistack';
import { createContext, useContext } from 'react';
import { useQuery } from 'react-query';

type User = {
  id: number;
  username: string;
} | null;

type AuthContextType = {
  user: User;
  login: (username: string, password: string) => Promise<void>;
  logout: () => Promise<void>;
  loading: boolean;
  getTokenString: () => string | null;
  refetch: () => Promise<any>;
};
const AuthContext = createContext<AuthContextType>({
  user: null,
  login: async () => {},
  logout: async () => {},
  loading: true,
  getTokenString: () => null,
  refetch: async () => {},
});

const AuthProvider: React.FC = ({ children }) => {
  const { enqueueSnackbar } = useSnackbar();

  const getToken = () => localStorage.getItem('token') || '';
  const getTokenString = () => `Bearer ${getToken()}`;
  const { isLoading, data, refetch } = useQuery<{ user: User }>(
    'me',
    () =>
      axios
        .get('/api/auth/me', {
          headers: { Authorization: getTokenString() },
        })
        .then((result) => result.data),
    { refetchOnWindowFocus: false }
  );
  // const router = useRouter();

  const login = async (username: string, password: string) => {
    const { data } = await axios.post(`/api/auth/login`, {
      username,
      password,
    });

    const { token, error } = data;
    if (!error && token) {
      localStorage.setItem('token', token);
      refetch();
    } else if (error) {
      // Snackbar
      enqueueSnackbar(error, {
        variant: 'error',
      });
    }
  };
  const logout = async () => {
    // router.push('/login');
    localStorage.removeItem('token');
    refetch();
  };

  return (
    <AuthContext.Provider
      value={{
        user: data?.user || null,
        logout,
        login,
        loading: isLoading,
        getTokenString,
        refetch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
const useAuthContext = () => useContext(AuthContext);

export { AuthContext, AuthProvider, useAuthContext };
