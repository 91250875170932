// @refresh reset
import { ApolloProvider } from '@apollo/client';
import DateFnsUtils from '@date-io/date-fns';
import { CssBaseline, ThemeProvider } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { RewriteFrames } from '@sentry/integrations';
import * as Sentry from '@sentry/node';
import { AppProps } from 'next/app';
import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { SnackbarProvider } from 'notistack';
import { useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main css file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { QueryClient, QueryClientProvider } from 'react-query';

import Layout from '@/components/Layout';
import { AuthProvider } from '@/contexts/auth';
import { QueryParamProviderComponent } from '@/contexts/queryParam';
import '@/styles.css';
import theme from '@/theme';
import { useApollo } from 'lib/apolloClient';
if (process.env.NEXT_PUBLIC_SENTRY_DSN) {
  const config = getConfig();
  const distDir = `${config.serverRuntimeConfig.rootDir}/.next`;
  Sentry.init({
    enabled: process.env.NODE_ENV === 'production',
    integrations: [
      new RewriteFrames({
        iteratee: (frame) => {
          frame.filename = frame.filename.replace(distDir, 'app:///_next');
          return frame;
        },
      }),
    ],
    dsn: process.env.NEXT_PUBLIC_SENTRY_DSN,
  });
}

const queryClient = new QueryClient();

const App = ({ Component, pageProps, err }: AppProps & { err: unknown }) => {
  const apolloClient = useApollo(pageProps.initialApolloState);

  useEffect(() => {
    // Remove the server-side injected CSS.
    const jssStyles = document.querySelector('#jss-server-side');
    if (jssStyles) {
      jssStyles.parentElement.removeChild(jssStyles);
    }
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <QueryParamProviderComponent>
        <ThemeProvider theme={theme}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <ApolloProvider client={apolloClient}>
              <CssBaseline />
              <Head>
                <title>Castle Peak</title>
                <meta
                  name="viewport"
                  content="minimum-scale=1, initial-scale=1, width=device-width"
                />
              </Head>
              <SnackbarProvider maxSnack={3} autoHideDuration={5000}>
                <AuthProvider>
                  <Layout>
                    <Component {...pageProps} err={err} />
                  </Layout>
                </AuthProvider>
              </SnackbarProvider>
            </ApolloProvider>
          </MuiPickersUtilsProvider>
        </ThemeProvider>
      </QueryParamProviderComponent>
    </QueryClientProvider>
  );
};

export default dynamic(() => Promise.resolve(App), {
  ssr: false,
});
